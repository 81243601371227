import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/mogiv",
  "project": "Mogiv",
  "projectID": "mogiv",
  "projectURL": "https://www.mogiv.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="mogiv_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryImg src="mogiv_how.png" alt="How page screenshot" title="How" mdxType="GalleryImg" />
  <GalleryImg src="mogiv_tutorials.png" alt="Tutorials page screenshot" title="Tutorials" mdxType="GalleryImg" />
  <GalleryImg src="mogiv_contact.png" alt="Contact page screenshot" title="Contact" mdxType="GalleryImg" />
  <GalleryImg src="mogiv_blog.png" alt="Blog page screenshot" title="Blog" mdxType="GalleryImg" />
  <GalleryImg src="mogiv_blog-post-meta.png" alt="Blog Post Meta block screenshot" title="Blog Post Meta block" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      